import { loadStripe } from '@stripe/stripe-js'
import { CustomerApi, Price } from '../api'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')
const customerApi = new CustomerApi(process.env.REACT_APP_CUSTOMER_API_URL || '')
export interface PriceData {
    interval: string
    amount: string
}


export const goToCheckout = async (sessionId: string) => {
    const stripeObj  = await stripePromise

    if(!stripeObj) {console.log('obj is null');return}

    const { error } = await stripeObj.redirectToCheckout({
        sessionId
    })

    if(error){
        console.log(error)
        throw error
    }
}