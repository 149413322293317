import { FC, useEffect } from "react";
import { Menu } from "antd";
import { HomeOutlined, BookOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "@reach/router";
import { Actions, State, useStoreActions, useStoreState } from "easy-peasy";
import { StoreModel } from "../../store";
import { useLocation } from "@reach/router";
import { useAuth0 } from "@auth0/auth0-react";

const { Item } = Menu;

interface NavigationMenuProps {
  style?: React.CSSProperties;
}

export const NavigationMenu: FC<NavigationMenuProps> = ({ style }) => {
  const selectedKey = useStoreState(
    (state: State<StoreModel>) => state.globalState.menuActiveKey
  );
  const setMenuActiveKey = useStoreActions(
    (actions: Actions<StoreModel>) => actions.globalState.setMenuActiveKey
  );
  const location = useLocation();
  const pathKey = location.pathname.replace("/", "");
  const { user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    var currentKey = pathKey === "" ? "home" : pathKey;
    if (currentKey !== selectedKey) {
      setMenuActiveKey(currentKey);
    }
  }, [selectedKey]);

  return (
    <Menu
      mode={"horizontal"}
      defaultSelectedKeys={["home"]}
      style={style}
      selectedKeys={[selectedKey]}
    >
      {user ? (
        <Item
          title={"Profile"}
          icon={<UserOutlined />}
          key={"profile"}
          onClick={() => console.log("profile")}
        >
          <Link to={"/profile"}> Profile</Link>
        </Item>
      ) : (
        <Item
          title={"Login"}
          icon={<UserOutlined />}
          key={"login"}
          onClick={() => loginWithRedirect()}
        >
          Login
        </Item>
      )}
      <Item title={"Home"} icon={<HomeOutlined />} key={"home"}>
        <Link to={"/"}> Home</Link>
      </Item>
      <Item title={"Rulesets"} icon={<BookOutlined />} key={"ruleset"}>
        <Link to={"/ruleset"}> Rulesets</Link>
      </Item>
    </Menu>
  );
};
