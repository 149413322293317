import { action } from 'easy-peasy';
import { SubscriptionContainerState } from './interfaces'

export const subscriptionContainerState: SubscriptionContainerState = {
    apiKey: undefined,
    subscriptionData: undefined,
    setApiKey: action((state, payload) => {
        state.apiKey = payload
    }),
    setSubscriptionData: action((state, payload) => {
        state.subscriptionData = payload
    })
}