import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "@reach/router";

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV !== "local"
      ? process.env.REACT_APP_SENTRY_DNS
      : undefined,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: process.env.REACT_APP_ENV == "production" ? 0.7 : 1.0,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

const onRedirectCallback = (appState: AppState | undefined) => {
  navigate(appState && appState.returnTo ? appState.returnTo : "/");
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      redirectUri={`${window.location.origin}`}
      audience={"https://one.3coresec.api"}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={"localstorage"}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
