
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { FC } from 'react'
import { Layout, Card, Typography } from 'antd'
import { LinkOutlined } from '@ant-design/icons'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout.Content style={props.styles.mainContent}>
    <Typography.Title>Getting Started</Typography.Title>
    <Card title={<Typography.Title style={props.styles.title}>Quick start for security researchers</Typography.Title>} headStyle={props.styles.welcomeCardTitle} style={props.styles.cardStyle} mdxType="Card">
        <Typography.Paragraph style={props.styles.welcomeCardTypography}>
            <p>In this page we'll provide you with general information of what you should expect when making your content available in the marketplace.</p>
            <p>Let's start by addressing one of the most important aspects of dtection.io: you receive 100% of the amount charged for the subscriptions you decided to sell with us. No small letters or algorithmic calculations at the end of the month.</p>
            <p>The first step is deciding which content you'd like to publish. If you're going for Snort/Suricata signatures you can develop the rules entirely (and just publish the content to dtection.io backend) or you can provide us with simple lists (think IP addresses, file paths, etc) so that we can build the signatures for you. The same applies to Zeek Intel Framework content.</p>
            <p>For rules or rule packs in the Sigma format it's recommended to keep individual signatures in separate files and just group the files (or folder) into a ZIP archive. Our backend will then create the checksum of that archive on each push and our customers will rely on that checksum to know when to update their subscription (the same applies to Snort/Suricata rules).</p>
            <p>After deciding which and how content will be published you will receive a set of credentials from us that will allow you to publish your content. After the content is published dtection.io will handle all distribution/updates automatically.</p>
            <p>While it's pretty simple to publish content to the dtection.io backend API, we developed several helper scripts and functions to make this process faster/easier. As an example, you can use our <a href={'https://github.com/3CORESec/dtio-kb#github-actions'} target='__blank'>CI/CD example pipeline</a> to automate publishing the content from your private Github repository. All you do is commit to your repo and the Github Action does the rest!</p>
            <p>Please visit <a href={'https://github.com/3CORESec/dtio-kb'} target='__blank'>DTIO KB in Github</a> for more information.</p>
            <p>Have an idea for a different technology that we should support? A different distribution method? Let us know!</p>
        </Typography.Paragraph>
    </Card>
    <div style={props.styles.divider} />
    <Card headStyle={props.styles.welcomeCardTitle} title={<Typography.Title style={props.styles.title}>Let's go!</Typography.Title>} style={props.styles.cardStyle} bodyStyle={props.styles.cardBodyStyle} mdxType="Card">
        <Card.Grid style={props.styles.cardGrid} hoverable={false}>
            <Typography.Paragraph>
                <p>The first thing you need to do to get started is to submit the <a href={'https://forms.gle/cCh41GSCTkHPe3Qr9'} target='__blank'>Researcher onboard form</a>. After that we'll review your request and follow up with additional information.</p>
                <p>If you'd like to reach out to us you can e-mail us at <strong>dtection@3coresec.com</strong>, sign up for our <a href={'https://launchpass.com/3coresec'} target='__blank'>Community Slack</a> or ping us on <a href={'https://twitter.com/3CORESec'} target='__blank'>Twitter</a>.</p>
            </Typography.Paragraph>
        </Card.Grid>
    </Card>
    
    </Layout.Content>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;