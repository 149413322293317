import { FC } from 'react';
import { Divider, Layout, Typography } from 'antd';
import { NavigationMenu } from './navigation-menu'
import { SubscriptionStatusContainer } from './subscription-status-container'
import { generateModuleCss } from '../utils'
import { useMediaQuery } from 'react-responsive'

const { Header, Footer, Content } = Layout
const isFF = navigator.userAgent.indexOf('Firefox') != -1
const fitContentValue = isFF? 'fit-content' : '-moz-fit-content'

const styles = generateModuleCss({
    containerDiv: { },
    layout: { minHeight: '100vh', },
    header: { padding: 0, zIndex: 1 },
    headerDiv: {
        display: 'flex',
        paddingRight: '0px', 
        placeContent: 'space-between',
        width: '100%',
        height: 'inherit'
    },
    headerImg: {height: 'inherit'},
    subscriptionContainer: {
        marginLeft: '5%',  
        marginRight: '10%', 
        minWidth:'35% '
    },
    navigation: { float: 'right', minWidth: fitContentValue },
    footer: { background: '#1f1f1f', width: '100%'},
    footerContent: { display: 'flex', justifyContent: 'center'},
    paragraph: { margin: '0px'},
    footerLogo: { marginLeft: '5px' },
    footerDivider: { height: 'inherit'}
})

//mobile styles, maybe move this to a styles dir
const mobile = generateModuleCss({
    layout: { minHeight: '100vh', },
    header: { padding: 0, zIndex: 1, height: 'auto' },
    headerDiv: {
        paddingRight: '0px', 
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    headerImg: { 
        height: '90px', 
        textAlign: 'center', 
        maxWidth: '400px',
        alignSelf: 'center' 
    },
    subscriptionContainer: {
        margin: 'auto',
        width:'90% ',
        minWidth: '300px'
    },
    navigation: { textAlign: 'center', width: '100%' },
    footer: { background: '#1f1f1f', width: '100%', position: 'relative', maxHeight: '70px'},
    footerContent: { display: 'flex', justifyContent: 'center'},
    paragraph: { margin: '0px'},
    footerLogo: { marginLeft: '0px' },
    footerDivider: { height: 'inherit'}
})

export const MainLayout: FC = ({children}) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1080px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1079px)' })

    return (
        <>
            {isDesktopOrLaptop &&
            <Layout 
            style={styles.layout}
            >
                <Header style={styles.header}>
                    <div style={styles.headerDiv}>
                        <img src={'/logo.png'} style={styles.headerImg} alt={''}/>
                        <SubscriptionStatusContainer style={styles.subscriptionContainer}/>
                        <NavigationMenu style={styles.navigation}/> 
                    </div>
                </Header>
                
                {children}
                <Footer style={styles.footer}>
                    <Content style={styles.footerContent}>
                        <Typography>A project by </Typography>
                        <img src={'/3cs-logo.png'} alt={''} width={'70px'} style={styles.footerLogo}/>
                        <Divider type={'vertical'} style={styles.footerDivider}/> 
                        <a href='/TermsOfService' target='_blank'>Terms of Service</a>
                    </Content>
                </Footer>
            </Layout>}
            {isTabletOrMobile &&
            <Layout 
            style={mobile.layout}
            >
                <Header style={mobile.header}>
                    <div style={mobile.headerDiv} key={'header-div'}>
                        <img src={'/logo.png'} style={mobile.headerImg} alt={''}/>
                        <SubscriptionStatusContainer style={mobile.subscriptionContainer}/>
                        <NavigationMenu style={mobile.navigation}/> 
                    </div>
                </Header>
                
                {children}
                <Footer style={mobile.footer}>
                    <Content style={mobile.footerContent}>
                        <Typography>A project by </Typography>
                        <img src={'/3cs-logo.png'} alt={''} width={'70'} style={mobile.footerLogo}/>
                        <Divider type={'vertical'} style={mobile.footerDivider}/> 
                        <a href='/TermsOfService' target='__blank'>Terms of Service</a>
                    </Content>
                </Footer>
            </Layout>}
        </>
    )
}
