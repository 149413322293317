import {action, thunk} from 'easy-peasy'
import { RulesetState } from './interfaces'

export const rulesetState: RulesetState = {
    authors: [],
    formats: [],
    filterType: undefined,
    filter: undefined,

    setFilter: action((state, payload) => {
        state.filter = payload
    }),

    setFilterType: action((state, payload) => {
        state.filterType = payload
    }),

    setFormats: action((state, payload) => {
        state.formats = payload
    }),

    setAuthors: action((state, payload) => {
        state.authors = payload
    }),

    getDataFromRules: thunk(async (actions, payload) => {
        const authors: string[] = []
        const formats: string[] = []

        payload.forEach((rule) => {
            authors.push(rule.author.name)
            formats.push(rule.format)
        })

        const authorSet = new Set(authors)
        const formatSet = new Set(formats)
        actions.setAuthors([...authorSet])
        actions.setFormats([...formatSet])
    })
}