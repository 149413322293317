import {FC, useState} from 'react'
import { Card, Button, Input, Typography, Modal, notification, Popconfirm} from 'antd'
import {CheckCircleOutlined} from '@ant-design/icons'
import { CustomerApi, SubscriptionApi,  } from '../../api'
import { Actions, State, useStoreActions, useStoreState } from 'easy-peasy'
import { StoreModel } from '../../store'
import { generateModuleCss } from '../utils'

const subscriptionApi = new SubscriptionApi(process.env.REACT_APP_SUBSCRIPTION_API_URL || '')
const customerApi = new CustomerApi(process.env.REACT_APP_CUSTOMER_API_URL || '')

const styles = generateModuleCss({
    mainDiv: { 
        display: 'flex', 
        marginTop: 'auto', 
        marginBottom: 'auto'
    },
    apiKeyInput: { minWidth:'fit-content' },
    checkButton: { marginLeft: '1rem' },
    statusCard: { margin: '10px 0' }
})

export interface SubscriptionStatusContainerProps {
    style? : React.CSSProperties
}

export const SubscriptionStatusContainer: FC<SubscriptionStatusContainerProps> = ({style = {}}) => {
    const [input, setInput] = useState<string>()
    const [showInfoCard, setShowInfoCard] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const apiKey = useStoreState((state: State<StoreModel>) => state.subscriptionContainerState.apiKey)
    const subscriptionData = useStoreState((state: State<StoreModel>) => state.subscriptionContainerState.subscriptionData)
    const setApiKey = useStoreActions((actions: Actions<StoreModel>) => actions.subscriptionContainerState.setApiKey)
    const setSubscriptionData = useStoreActions((actions: Actions<StoreModel>) => actions.subscriptionContainerState.setSubscriptionData)
 
    const handleManageClick = async () => {
        setIsLoading(true)
        if(!subscriptionData){
            setIsLoading(false)
            return notification.error({
                message: 'Introduce a valid ApiKey first'
            })
        }
        try{
        const sessionUrl = await customerApi.createBillingSession(subscriptionData?.email, window.location.href)
        setIsLoading(false)
        window.location.href = sessionUrl

        }catch(err){
            setIsLoading(false)
            return notification.error({
                message: err.message || 'Oops something went wrong'
            })
        }
    } 

    const handleCancelSubClick = async () => {
        setIsLoading(true)
        if(!subscriptionData){
            setIsLoading(false)
            return notification.error({
                message: 'Introduce a valid ApiKey first'
            })
        }
        try{
        const sessionUrl = await subscriptionApi.cancelSubscription(apiKey || "", subscriptionData?.rulesetId)
        if(sessionUrl.status !== 200){
            setIsLoading(false)
            return notification.error({
                message: sessionUrl.data.message || 'Oops something went wrong'
            })
        }
        setIsLoading(false)
        setShowInfoCard(false)
        setApiKey(undefined)
        return notification.info({
            message: 'Subscription cancelled succesfully'
        })
        }catch(err){
            setIsLoading(false)
            return notification.error({
                message: err.message || 'Oops something went wrong'
            })
        }
    }

    const handleCheckClick = async () => {
        setIsLoading(true)
        if(!input){
            setIsLoading(false)
            return notification.error({
                message: 'Not a valid Access Key'
            })
        }
        
        if(input !== apiKey){
            try{
                const result = await subscriptionApi.getSubscriptionStatus(input)
                setApiKey(input)
                setSubscriptionData(result)
                setShowInfoCard(true)
                setIsLoading(false)
            }catch(err){
                setApiKey(undefined)
                setIsLoading(false)
                const resp = err.response
                const msg = resp && resp.status === 403? 'Not a valid api key' : err.message
                return notification.error({
                    message: msg || 'Oops something went wrong'
                })
            }
        }

        setShowInfoCard(true)
        setIsLoading(false)
    }

    return(
    <div style={{ ...styles.mainDiv ,...style }}>
        
        <Input
         style={styles.apiKeyInput}
         addonBefore={'Access Key'}
         onChange={(e) => { setInput(e.target.value) }}
         placeholder={'Check on your subscription status ...'}
        />
        <Button
         type='primary'
         icon={<CheckCircleOutlined />}
         onClick={handleCheckClick}
         style={styles.checkButton}
         loading={isLoading}
        >
            Check
        </Button>

        <Modal
            closeIcon
            visible={showInfoCard}
            onCancel={() => setShowInfoCard(false)}
            afterClose={() => setShowInfoCard(false)}
            footer={[
                <Button onClick={handleManageClick} loading={isLoading} type={'primary'}>Change Payment Method</Button>,
                <Popconfirm 
                 title={'Are you sure you want to proceed?'}
                 okType='danger'
                 okText='Cancel Subscription'
                 cancelText='Close'
                 onConfirm={handleCancelSubClick}
                 placement='right'
                >
                    <Button loading={isLoading} type={'primary'} danger>Cancel Subscription</Button>
                </Popconfirm>,
                <Button key='back' onClick={() => setShowInfoCard(false)} danger>
                    Close
                </Button>,
                ]}
            >
                {subscriptionData && 
                <Card 
                 title={subscriptionData.ruleset} style={styles.statusCard}
                >
                    <ul>
                        <li>
                            {`Number of IPs in use: ${subscriptionData.networksCount}`}
                        </li>
                        <li>
                            {`Total IPs allowed: ${subscriptionData.maxNetworks}`}
                        </li>
                        <li>
                            {`Next renewal date: ${subscriptionData.nextRenewal}`}
                        </li>
                    </ul>
                </Card>}
            </Modal>
    </div>)
}
