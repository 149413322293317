import { CSSProperties, FC } from 'react'
import { Cascader, Typography } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import { Actions, State, useStoreActions, useStoreState } from 'easy-peasy'
import { Ruleset } from '../../api'
import { StoreModel } from '../../store'
import { CascaderOptionType, CascaderValueType } from 'antd/lib/cascader'
import { FilterType } from '../utils'

const { Title } = Typography

const options: CascaderOptionType[] = [
    {
        value: 'Author',
        label: 'Author',   
    },
    {
        value: 'Format',
        label: 'Format',  
    }
]
export interface RulesFilterProps {
    style?: CSSProperties
    rulesets?: Ruleset[]
}

const transformToOptionChildren = (values: string[]) => {
    return values.map( val => {
        return {
            value: val,
            label: val
        }
    })
}

const getFilterType = (val: string | number) => {
   if(val === FilterType.AUTHOR || val === FilterType.FORMAT){
       return val as FilterType
   }

   return undefined 
}

export const RulesFilter: FC<RulesFilterProps> = ({style}) => {
    const authors = useStoreState((state: State<StoreModel>) => state.rulesetState.authors)
    const formats = useStoreState((state: State<StoreModel>) => state.rulesetState.formats)
   
    const setFilterType = useStoreActions((actions: Actions<StoreModel>) => actions.rulesetState.setFilterType)
    const setFilterValue = useStoreActions((actions: Actions<StoreModel>) => actions.rulesetState.setFilter)
    
    
    const onChangeHandler = (value: CascaderValueType,) => {
        //cleared the filter
        if(value.length === 0){
            setFilterType(undefined)
            setFilterValue(undefined)
        }

        const type = getFilterType(value[0])
        const filterVal = value.length === 2? value[1].toString() : undefined
        
        setFilterType(type)
        setFilterValue(filterVal)
    }
    //set options children
    options[0].children = transformToOptionChildren(authors) 
    options[1].children = transformToOptionChildren(formats)

    return (
        <>
            <div style={{display: 'inline-flex', ...style}}>
            <Title level={4}>
                <FilterOutlined/>
                <Cascader options={options} onChange={onChangeHandler} style={{marginTop: '10px', paddingLeft: '10px'}} placeholder={'Filter By...'}/>
            </Title>
            
            </div>
        </>
    )
}