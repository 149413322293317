import { useCallback } from 'react'
import {notification } from 'antd'

export const copyToClipboard = (copyText: string, notificationString: string) => {
    copyText && navigator.clipboard.writeText(copyText)
      .then(() => notificationString && notification.info({
        message: 'Info',
        description: notificationString,
        placement: 'topRight',
        duration: 6
  }))
}