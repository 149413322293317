import { FC, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { MainLayout } from "../layout";
import {
  Card,
  Typography,
  Divider,
  Button,
  Tag,
  Tooltip,
  Layout,
  Spin,
} from "antd";
import { generateModuleCss, copyToClipboard } from "../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useQuery } from "react-query";
import { CustomerSubscription, Ruleset } from "../../api/models";
import { AxiosError } from "axios";
import { CustomerApi, RulesetApi, SubscriptionApi } from "../../api";
import { RulesetCard } from "../rulesets";

const { Title, Text } = Typography;
const { Content } = Layout;

const rulesetApi = new RulesetApi(process.env.REACT_APP_RULESET_API_URL || "");
const customerApi = new CustomerApi(
  process.env.REACT_APP_CUSTOMER_API_URL || ""
);

const styles = generateModuleCss({
  cardBodyStyle: {
    width: "100%",
    display: "inline-flex",
    borderRadius: "10px",
  },
  cardStyle: {
    margin: "10px 0",
    borderRadius: "10px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  cardHeadStyle: {
    backgroundColor: "#444",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  titleStyle: { display: "block", whiteSpace: "normal" },
  auditStyle: { marginRight: "10px" },
  tagDivStyle: { float: "right", display: "flex" },
  gridStyle: { width: "100%" },
  subscribeButton: { float: "right" },
  actionsDiv: { display: "block" },
  copyIcon: { fontSize: "0.5em", paddingLeft: "10px", paddingTop: "5px" },
  tagStyle: { height: "fit-content", alignSelf: "center" },
  spinCentered: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    justifyContent: "center",
  },
  contentStyle: { padding: "1% 4%", display: "flex", flexDirection: "column" },
  mailFooterStyle: { display: "flex", flexDirection: "row-reverse" },
  logOutButton: { backgroundColor: "#FF0000" },
  emailCardBodyStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

export interface UserProfileProps extends RouteComponentProps<{}> {}

export const UserProfile: FC<UserProfileProps> = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    if (!user || !isAuthenticated) {
      navigate("/");
    }
  }, [user, isAuthenticated]);

  const {
    data: subscriptions = [],
    error: subscriptionQueryError,
    isLoading,
  } = useQuery<CustomerSubscription[], AxiosError>(
    "customerSubscription",
    async () => {
      if (!user || !isAuthenticated) {
        return [];
      }

      const result = await customerApi.getCustomerSubscriptions(
        user?.email || ""
      );
      return result || [];
    }
  );

  const {
    data: allRulesets = [],
    error: rulesetQueryError,
    isLoading: ruleSetLoading,
  } = useQuery<Ruleset[], AxiosError>(
    ["rulesetAllQuery", user],
    async () => {
      const rulesets = await rulesetApi.getAllRuleset();
      const customerRulesetids = subscriptions.map((it) => it.rulesetId);
      const userRulesets = rulesets.filter((it) =>
        customerRulesetids.includes(it.id)
      );
      return userRulesets || [];
    },
    {
      enabled: subscriptions !== undefined && subscriptions.length > 0,
    }
  );

  const renderRules = () => {
    if (!allRulesets || allRulesets.length <= 0) {
      return <></>;
    }

    return allRulesets.map((ruleset) => {
      const ruleUrl = `${window.location.hostname}/ruleset/${ruleset.id}`;

      return (
        <RulesetCard
          ruleset={ruleset}
          key={`${ruleset.id}-card`}
          url={ruleUrl}
        />
      );
    });
  };

  return (
    <MainLayout>
      <Content style={styles.contentStyle}>
        <Card
          bodyStyle={styles.emailCardBodyStyle}
          style={styles.cardStyle}
          headStyle={styles.cardHeadStyle}
          title={
            <Title style={styles.titleStyle} level={3}>
              Account
            </Title>
          }
        >
          {`Email: ${user?.email}`}
          <Divider />
          <div style={styles.mailFooterStyle}>
            <Button
              type="default"
              style={styles.logOutButton}
              onClick={() => logout({ returnTo: `${window.location.origin}` })}
            >
              <Text style={{ color: "#FFFFFF" }}>Log Out</Text>
            </Button>
          </div>
        </Card>

        {isLoading || ruleSetLoading ? (
          <div style={styles.spinCentered} key={"ruleset-spinner-div"}>
            <Spin tip={"Loading"} />
          </div>
        ) : (
          renderRules()
        )}
      </Content>
    </MainLayout>
  );
};
