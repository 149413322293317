import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { CustomerSubscription, PutCustomerPayload } from "./models";

export class CustomerApi {
  private baseUrl: string;

  constructor(url: string) {
    this.baseUrl = url;
  }

  putCustomer = (payload: PutCustomerPayload) => {
    const url = `${this.baseUrl}/session`;
    const body = JSON.stringify(payload);
    const options: AxiosRequestConfig = {
      headers: {
        ["Content-Type"]: "application/json",
      },
    };
    return axios
      .put<any, AxiosResponse<string>>(url, body, options)
      .then((resp) => resp.data);
  };

  hasSubscription = (email: string, productId: string) => {
    const url = `${this.baseUrl}/${encodeURIComponent(
      email
    )}/subscription/${productId}`;
    return axios
      .get<any, AxiosResponse<boolean>>(url)
      .then((resp) => {
        if (resp.status === 200) return true;
        return false;
      })
      .catch((err) => {
        const resp = err.response;

        if (resp && resp.status === 404) {
          return false;
        }
        throw err;
      });
  };

  getCustomerFromSessionId = (sessionId: string) => {
    const url = `${this.baseUrl}/session/${sessionId}`;
    return axios.get<any, AxiosResponse<string>>(url).then((resp) => resp.data);
  };

  createBillingSession = (email: string, returnUrl: string) => {
    const url = `${this.baseUrl}/${encodeURIComponent(email)}/session`;
    const body = JSON.stringify(returnUrl);
    const options: AxiosRequestConfig = {
      headers: {
        ["Content-Type"]: "application/json",
      },
    };

    return axios
      .post<any, AxiosResponse<string>>(url, body, options)
      .then((resp) => resp.data);
  };

  getCustomerSubscriptions = (email: string) => {
    const url = `${this.baseUrl}/${encodeURIComponent(email)}/subscriptions`;
    const options: AxiosRequestConfig = {
      headers: {
        ["Content-Type"]: "application/json",
      },
    };

    return axios
      .get<any, AxiosResponse<CustomerSubscription[]>>(url, options)
      .then((resp) => resp.data);
  };
}
