import axios, { AxiosResponse} from 'axios'
import { Ruleset, Price } from './models'

export class RulesetApi {
    private baseUrl : string

    constructor(url: string){
        this.baseUrl = url
    }

    getAllRuleset = async () => {
        const url = `${this.baseUrl}/all`
        return axios.get<any, AxiosResponse<Ruleset[]>>(url).then(resp => resp.data)
    }

    getRuleset = async (id: string) => {
        const url = `${this.baseUrl}/${id}`
        return axios.get<any, AxiosResponse<Ruleset>>(url).then(resp => resp.data)
    }


}
export const cena = ''