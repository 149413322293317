import { FC } from "react";
import { Card, Typography, Divider, Button, Tag, Tooltip } from "antd";
import {
  SecurityScanOutlined,
  CopyOutlined,
  TwitterOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { generateModuleCss, copyToClipboard } from "../utils";
import { Ruleset } from "../../api";
import DescriptionWrapper from "./description-wrapper";
import { url } from "inspector";

const { Title } = Typography;
const CopyNotificationTxt = "Ruleset URL copied to clipboard";
export interface RulesetCardProps {
  ruleset: Ruleset;
  onSubscribe?: () => void;
  url: string;
}

const styles = generateModuleCss({
  cardBodyStyle: {
    width: "100%",
    display: "inline-flex",
    borderRadius: "10px",
  },
  cardStyle: {
    margin: "10px 0",
    borderRadius: "10px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  cardHeadStyle: {
    backgroundColor: "#444",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  titleStyle: { display: "block", whiteSpace: "normal" },
  auditStyle: { marginRight: "10px" },
  tagDivStyle: { float: "right", display: "flex" },
  gridStyle: { width: "100%" },
  subscribeButton: { float: "right" },
  actionsDiv: { display: "block" },
  copyIcon: { fontSize: "0.5em", paddingLeft: "10px", paddingTop: "5px" },
  tagStyle: { height: "fit-content", alignSelf: "center" },
});
const centsToEuros = (cents: number) => {
  return (cents / 100).toString();
};
export const RulesetCard: FC<RulesetCardProps> = ({
  ruleset,
  url,
  onSubscribe,
}) => {
  return (
    <Card
      bodyStyle={styles.cardBodyStyle}
      style={styles.cardStyle}
      headStyle={styles.cardHeadStyle}
      title={
        <Title style={styles.titleStyle} level={3}>
          <SecurityScanOutlined style={styles.auditStyle} />
          {ruleset.title}
          <Tooltip
            title="Copy this ruleset permalink to clipboard"
            key={`tooltip-${ruleset.id}`}
          >
            <CopyOutlined
              onClick={() => copyToClipboard(url, CopyNotificationTxt)}
              style={styles.copyIcon}
              height={"0.5em"}
            />
          </Tooltip>
          <div style={styles.tagDivStyle}>
            <Tag
              color={"geekblue"}
              key={`${ruleset.id}-Format`}
              style={styles.tagStyle}
            >
              Format: {ruleset.format}
            </Tag>
            <Tag
              color={"green"}
              key={`${ruleset.id}-Auhtor`}
              style={styles.tagStyle}
            >
              Author: {ruleset.author.name}
            </Tag>
            <Title level={4} style={{ marginBottom: "0px" }}>
              {ruleset.author.twitter && (
                <a href={ruleset.author.twitter} target={"_blank"}>
                  <TwitterOutlined />
                </a>
              )}
              {ruleset.author.site && (
                <a
                  href={ruleset.author.site}
                  style={{ marginLeft: "10px" }}
                  target={"_blank"}
                >
                  <GlobalOutlined />
                </a>
              )}
            </Title>
          </div>
        </Title>
      }
    >
      <div style={styles.gridStyle}>
        <DescriptionWrapper mdx={ruleset.description} />
        <Divider />
        {onSubscribe ? (
          <div style={styles.actionsDiv}>
            <Button
              onClick={onSubscribe}
              type={"primary"}
              style={styles.subscribeButton}
            >
              Subscribe <Divider type={"vertical"} />{" "}
              {centsToEuros(ruleset.prices[0]?.amount)}€ / year
            </Button>
          </div>
        ) : (
          <div style={styles.actionsDiv}>
            <Button disabled type={"primary"} style={styles.subscribeButton}>
              Subscribed
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};
