import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse} from 'axios'
import { SubscriptionStatus } from './models'

export class SubscriptionApi {
    private baseUrl : string

    constructor(url: string){
        this.baseUrl = url
    }

    postSubscription = (email: string, rulesetId: string) => {        
        const url = `${this.baseUrl}/ruleset/${rulesetId}`
        const body = JSON.stringify(email);
        const options: AxiosRequestConfig = {
            headers: {
                ['Content-Type']: 'application/json'
            }
        }
        return axios.post<any, AxiosResponse<any>>(url, body, options)
    }

    getSubscriptionStatus = (apiKey: string) => {
        const url = `${this.baseUrl}/${apiKey}/status`
        return axios.get<any, AxiosResponse<SubscriptionStatus>>(url).then(resp => resp.data)
    }

    cancelSubscription = (apiKey: string, rulesetId: string) => {
        const url = `${this.baseUrl}/ruleset/${rulesetId}?api_key=${apiKey}`
        return axios.delete<any, AxiosResponse>(url)
    }
}