
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Before using our services, please read these Terms & Conditions which establish the rules governing and applying to the services provided by us (“Services”) through our ("Website" or “Platform”) and form a legally binding agreement between you and dtection.io.`}</p>
    <p>{`dtection.io is a platform owned by 3CSEC - IT INFRASTRUCTURE SECURITY UNIPESSOAL LDA, a company with registered address at Avenida da Liberdade - UPTEC Mar, sala C1, Leça da Palmeira 4450 - 718 Matosinhos and with taxpayer and company number 515859125 (hereinafter referred to as “3CSEC”, “we” or “us”).`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`ACCEPTANCE OF THESE TERMS & CONDITIONS`}</strong></li>
    </ol>
    <p>{`These terms and conditions ("Terms & Conditions") shall apply to all users of our platform dtection.io. We advise you to, before accepting it, read this document carefully, as it creates a legally binding Contract between you (User) and dtection.io.`}</p>
    <p>{`You acknowledge that by accessing and using our Platform, that you have read, understood, and accept these Terms & Conditions, you will be agreeing to be bound by these Terms & Conditions and by any rules, bylaws and internal regulations of our platform that are in force at the time and have been notified to all the users.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`WHO WE ARE AND WHAT WE DO`}</strong></li>
    </ol>
    <p>{`dtection.io is a marketplace platform focused in the cyber security space. We provide an ecosystem where developers and researchers (Content Creators) can publish and license their findings regarding threat detection matters and customers can purchase one year licenses to use these findings, directly from our Content Creators.`}</p>
    <p>{`Please remember that dtection.io is a marketplace for threat detection solutions and any content shared by the Content Creators in our platform, although designed to mitigate security flaws when applied to a determined system, is provided without any guarantees or assurances that they are the adequate response for your specific situation.`}</p>
    <p>{`The items we provide in our platform are published and sold exclusively by the Content Creators, which means that dtection.io is the mere intermediate of the transaction and plays no part in the agreement between the Licensor and the Licensee. For that matter, we do not benefit from any of the transactions made using our platform, with the exception of when 3CSEC is the Content Creator.`}</p>
    <p>{`Although we have high quality standards and abide by strict policies, we are not responsible for any of the content provided by third-party Content Creators nor do we guarantee that they will have the performance you expect. However, we understand that it is of essential importance to have your input and review any item you purchase. We would very much like to have your input regarding the items we provide in our marketplace.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`USERS`}</strong></li>
    </ol>
    <p>{`We intend to provide an ecosystem that will allow people to share their findings in a collaborative approach towards security. This will only be possible if we are all on the same page and determined to work together in good faith. This means that it is very important for us that while using our Marketplace, you follow these Terms. Essentially it is important your respect all applicable laws, including copyright or trademark laws or other laws in your jurisdiction that benefit our Content Creators. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations. As a User of dtection.io you will be able to buy a one year license to use content shared by our Content Creators. Please remember that only Content Creators are liable for the content share within our ecosystem. We advise you to directly contact the Content Creator to assist you, provide you with guidance or reply to any queries you may have regarding content.  `}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><strong parentName="li">{`CONTENT PROVIDED THROUGH OUR PLATFORM`}</strong></li>
    </ol>
    <p><strong parentName="p">{`Ownership of content`}</strong></p>
    <p>{`Content Creators own the content and findings they decide to share on our platform. However by sharing their Content, they will be allowing us certain rights, namely, to display and share their content with our Users. In return Users will pay our Content Creators for a one-year, non-exclusive, world-wide license to use the Content under these Terms of Use.`}</p>
    <p>{`Please remember that regarding Content you are prohibited to resell, copy, translate, modify, disassemble, decompile, transfer, distribute, make derivative works, disclose, publish, rent, lend, lease or sublicense to any third party, or otherwise use the Content in any manner that exceeds the scope of the use permitted under these T&C or in any manner inconsistent with applicable law.`}</p>
    <p><strong parentName="p">{`Liability for Content`}</strong></p>
    <p>{`Content Creators are solely responsible for the content of, and for any harm resulting from, any Content you decide to access and use. We are not responsible for any public display or misuse of Content.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`PRICE AND PAYMENT`}</strong></li>
    </ol>
    <p>{`The price of each item is determined exclusively by the Content Creator. The amount due for each item will allow you to use or access the content for the period of one year.`}</p>
    <p>{`Payment for purchases is made at the time of the order. You may freely and at any time decide to withdraw from our platform. However, we will not reimburse any amounts you have already paid.`}</p>
    <p>{`Payments are made on the platform through a bank card. The card must be registered in the platform by entering the card details and you can opt to allow the card to be remembered for future payments. By agreeing to these Terms, you are giving us permission to charge your on-file credit card, PayPal account, or other approved methods.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`WARRANTIES`}</strong></li>
    </ol>
    <p>{`The use of the platform is at your sole risk. Except as expressly set out in these Terms the platform is provided on an "as is" and "as available" basis and to the maximum extent permitted by the applicable law, exclusive of any warranties and representations, whether express, implied or statutory, including without limitation, warranties of merchantability, fitness for a particular purpose, non-infringement or warranty of uninterrupted services, all of which are hereby expressly disclaimed.`}</p>
    <p>{`dtection.io makes no warranty that (i) the platform or the content provided will meet your requirements, (ii) the platform will be uninterrupted, timely, secure, or error-free, (iii) any results, advice, recommendation or information that may be obtained from the use of the platform will be accurate, precise, complete, or free from errors or reliable, or (iv) the quality of the platform, functionalities, information, or other material obtained by you through the platform will meet your expectations.`}</p>
    <p>{`Since dtection.io is not a party to any purchase and sale made through the platform, the warranty obligations of the item are the sole responsibility of the Content Creator. In no case can dtection.io be called to answer for any warranty obligation of sold item.`}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol"><strong parentName="li">{`LIMITATION OF LIABILITY`}</strong></li>
    </ol>
    <p>{`In no event will dtection.io, its directors, employees, agents, partners, suppliers or content providers be liable under contract, tort, strict liability, negligence or any other legal or equitable theory with respect to the subject matter of these Terms for any lost profits, lost revenues, loss of reputation or goodwill, data loss, cost of procurement of substitute goods or services, or special, direct, indirect, incidental, punitive, or consequential damages of any kind whatsoever, substitute goods or services (however arising). The foregoing exclusions shall not apply to gross negligence or wilful misconduct.`}</p>
    <p>{`In addition to the above mentioned, dtection.io shall not be liable for damage or losses of any kind that may result from:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Any content made available on our platform that you decide to purchase and use;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use or inability to use the platform, including, in particular, delays, interruptions, errors, interference and suspension of communications, omissions, trojan horses, viruses, bugs, and damages and/or malfunctions;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Delays or usage blockages caused by deficiencies or overloads of the Internet or other electronic systems;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Suspension, malfunction or unauthorized use of the servers on which the platform is hosted;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Delays, interruption, incorrect functioning or malfunction of the systems and third networks which connect to the platform; and`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Illegitimate actions of third parties.`}</p>
      </li>
    </ul>
    <p>{`Nothing in these Terms & Conditions will limit or exclude any responsibility that dtection.io may have and which cannot be limited or excluded under the applicable law of the country you live in.`}</p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol"><strong parentName="li">{`PLATFORM'S OPERABILITY`}</strong></li>
    </ol>
    <p>{`dtection.io may suspend, block, stop or cease access to the platform, for all or only one or more users, in the following cases:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`When necessary due to technical, strategic, legal or business constraints;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When necessary to ensure the security of, namely, communication channels, equipment or information;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When it has strong suspicions of fraudulent or abusive use by you or a third party including the use of robot, spider, crawler, scraper, or other automated means or interface to access the platform or extract other users’ information;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When required by its management, maintenance, repair, modification or update operations of the equipment, systems or any of the Website’s functionalities;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In other circumstances duly justified, including, in particular, in the event of non-compliance by you of your obligations under these Terms & Conditions.`}</p>
      </li>
    </ul>
    <p>{`dtection.io further has the exclusive right to, at any time, permanently or temporarily, in whole or in part, at any time and at its discretion, shut down the platform or any of its functionalities without prior notice.`}</p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol"><strong parentName="li">{`INTELLECTUAL PROPERTY RIGHTS`}</strong></li>
    </ol>
    <p>{`You acknowledge that the platform may contain certain content (texts, images, graphics, sound and animation, and all other information available) which is protected by copyright, patent, trademark, trade secret or other proprietary rights and laws and that the platform, their structure and layout, the selection, organization and presentation of its contents, including their functionalities and the software used, as well as the trademarks, logos, and symbols which appear on the platform are owned or held by dtection.io or have been duly licensed to dtection.io.`}</p>
    <p>{`It is dtection.io’s responsibility to manage the design, layout and structure of all information, content and materials on the platform, and as such dtection.io may, at any time, update, modify or erase any content, services, options or features, as well as modify its presentation and configuration and modify its URL address. You are not allowed to transfer, broadcast, publish, make available to the public, modify, transform, copy, sell, use or distribute, in any form, the texts, images, or other information contained on the platform or part thereof without dtection.io’s prior written consent.`}</p>
    <p>{`The dtection.io name and logos are trademarks and service marks of 3CSEC (collectively the "dtection.io Trademarks"). The use of dtection.io Trademarks on the platform does not grant, nor may it be interpreted as granting permission for you to use, directly or indirectly, such dtection.io Trademarks.`}</p>
    <p>{`Except as expressly authorized by dtection.io, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the platform or reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in the platform.`}</p>
    <p>{`You must not use the platform to develop or commercialize any solution or to compete with the dtection.io or in any other way which infringes the intellectual property rights of dtection.io. Any rights not expressly granted herein are reserved by dtection.io.`}</p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol"><strong parentName="li">{`SUPPORT`}</strong></li>
    </ol>
    <p>{`Please remember that any support or questions regarding content you decide to purchase will be provided by the Content Creator and not by Us.`}</p>
    <p>{`However, you are always welcome to give us your thoughts and comments about dtection.io and the Services. In case you have any questions concerning these Terms & Conditions, you should send us an e-mail at `}<a parentName="p" {...{
        "href": "mailto:dtection@3coresec.com"
      }}>{`dtection@3coresec.com`}</a>{` or write to us at Avenida da Liberdade - UPTEC Mar, sala C1, Leça da Palmeira 4450 - 718 Matosinhos.`}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol"><strong parentName="li">{`PERSONAL DATA`}</strong></li>
    </ol>
    <p>{`We do not collect any personal data. The only information we collect is your e-mail and IP address to manage and guarantee you are able to access your subscriptions. Without this information we are unable to provide you with the Service. This information is deleted as soon as a subscription is cancelled or expired and is not transferred to any third parties or countries outside the European Economic Area. If you have any further questions regarding this topic please contact us at `}<a parentName="p" {...{
        "href": "mailto:dtection@3coresec.com"
      }}>{`dtection@3coresec.com`}</a>{`.`}</p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol"><strong parentName="li">{`AMENDMENTS`}</strong></li>
    </ol>
    <p>{`dtection.io reserves the right to amend these Terms in any way at any time. Whenever changes are made to the applicable Terms, a notice will be displayed when you access the Platform, please visit our Terms regularly to confirm if we have included any changes that could impact your use of our Service.`}</p>
    <p>{`In some cases we will request you accept new Terms. In these cases, if you decide not to accept or if you do not agree with our changes, we will stop providing you with our services. Amendments to the Terms will not be applied retroactively and will come into force on the day they are published.`}</p>
    <p>{`In the eventual termination of the agreement, the User accepts that any benefits they have obtained will be automatically cancelled, with no right to reimbursement.`}</p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol"><strong parentName="li">{`GENERAL TERMS`}</strong></li>
    </ol>
    <p>{`Nullity: The nullity of any clause of these Terms shall not affect the validity of the others, under the terms of article 292 of the Civil Code. The errors, omissions and lapses do not affect the validity and will not affect the rights of either party, but must be corrected as soon as they are detected, so that the situation achieved is that which would have been presented if the error, omission or lapse had not occurred.`}</p>
    <p>{`Prevalence: In case of conflict between the Terms and other specific provisions or specific terms and conditions existing on the Application, these Terms shall prevail.`}</p>
    <p>{`Exercise of Rights: The non-exercise or immediate enforcement of any rights or provisions by dtection.io under these Terms shall not be construed as a waiver of such rights or provisions.`}</p>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol"><strong parentName="li">{`JURISDICTION`}</strong></li>
    </ol>
    <p>{`The Terms, as well as the User relationship with dtection.io are governed by Portuguese law. For all questions arising from or related to these Terms, the competent jurisdiction is the District Court of Porto, with the express renunciation of any other.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;