import { FC, useEffect, useState } from "react";
import {
  Modal,
  Card,
  Button,
  Typography,
  Input,
  notification,
  Checkbox,
} from "antd";
import { CustomerApi, Price, Ruleset } from "../../api";
import { goToCheckout } from "../../services";
import { generateModuleCss } from "../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { UserOutlined } from "@ant-design/icons";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store";
import { navigate } from "@reach/router";

const MAIL_VALIDATION_REGEX = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
const { Title, Text } = Typography;
const customerApi = new CustomerApi(
  process.env.REACT_APP_CUSTOMER_API_URL || ""
);

const styles = generateModuleCss({
  emailInput: { margin: "10px 0" },
  tosDiv: { display: "flex" },
  checkboxTextStyle: { marginLeft: "10px" },
  oneAccountText: { display: "flex" },
  oneAccountDiv: { display: "flex", marginTop: "16px" },
  containerDiv: { display: "flex", flexDirection: "column" },
  incoStyle: { margin: "auto 10px auto 0px" },
});

export interface PaymentContainerProps {
  prices: Price[];
  onCancel: () => void;
  visible: boolean;
  ruleset?: Ruleset;
}

export const PaymentContainer: FC<PaymentContainerProps> = ({
  visible,
  onCancel,
  ruleset,
  prices,
}) => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const setRedirectUri = useStoreActions(
    (actions: Actions<StoreModel>) => actions.globalState.setRedirectUri
  );
  const { user, isAuthenticated } = useAuth0();

  const handleSubscriptionConfirm = async (
    email: string,
    price: string,
    cb?: () => void
  ) => {
    if (!ruleset) {
      return;
    }
    try {
      if (await customerApi.hasSubscription(email, ruleset.stripeId)) {
        return notification.error({
          message:
            "There's already a subscription to this product associated with this email",
        });
      }

      const cancelUrl = window.location.href;
      const successUrl = `${window.location.href}?session_id={CHECKOUT_SESSION_ID}&ruleset=${ruleset.id}`;
      //create session
      const sessionId = await customerApi.putCustomer({
        email: email,
        price,
        successUrl,
        cancelUrl,
      });
      goToCheckout(sessionId);
      setLoading(false);
      setTermsAccepted(false);
    } catch (err: any) {
      return notification.error({
        message: err.message || "Oops something went wrong",
      });
    }
  };

  const subscriptionConfirmation = () => {
    setLoading(true);
    if (!MAIL_VALIDATION_REGEX.test(email)) {
      setLoading(false);
      return notification.error({ message: "Not a valid email" });
    }

    if (!termsAccepted) {
      setLoading(false);
      return notification.error({
        message: "You must accept the Terms of Service first",
      });
    }
    handleSubscriptionConfirm(email, prices[0]?.id);
  };

  const handleCancel = () => {
    if (loading) {
      setLoading(false);
    }
    setTermsAccepted(false);
    onCancel();
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      setEmail(user.email || "");
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    setRedirectUri(window.location.pathname);
  });
  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button
            onClick={subscriptionConfirmation}
            type={"primary"}
            loading={loading}
          >
            Subscribe
          </Button>,
          <Button danger type={"primary"} onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Input
          style={styles.emailInput}
          disabled={isAuthenticated && !!user}
          addonBefore={"Email"}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder={
            isAuthenticated && !!user
              ? user.email
              : "Email to send your Access Key"
          }
        />
        <div style={styles.containerDiv}>
          <div style={styles.tosDiv}>
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <Text style={styles.checkboxTextStyle}>
              I've read and accept the{" "}
              <a href="/TermsOfService" target="_blank">
                Terms of Service
              </a>
            </Text>
          </div>
          {(!user || !isAuthenticated) && (
            <div style={styles.oneAccountDiv}>
              <UserOutlined style={styles.incoStyle} />
              <Text style={styles.oneAccountText}>
                Already have a 3CORESec One account?
                <a
                  onClick={() => navigate("/login-callback")}
                  style={{ marginLeft: "8px" }}
                >
                  Log in.
                </a>
              </Text>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
