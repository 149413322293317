import { action } from "easy-peasy";
import { GlobalState } from "./interfaces";

export const globalState: GlobalState = {
  menuActiveKey: "",
  redirectUri: window.location.href,
  setMenuActiveKey: action((state, payload) => {
    state.menuActiveKey = payload;
  }),
  setRedirectUri: action((state, payload) => {
    state.redirectUri = payload;
  }),
};
