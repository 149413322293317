import { FC, useMemo } from "react";
import { MainLayout } from "../layout";
import { Layout, Result, Button } from "antd";
import { generateModuleCss } from "./types";
import { Link, RouteComponentProps } from "@reach/router";

const { Content } = Layout;
const styles = generateModuleCss({
  mainContent: { padding: "25px" },
});

export interface ErrorPageProps extends RouteComponentProps<{}> {
  code: 403 | 404;
}

export const ErrorPage: FC<ErrorPageProps> = ({ code }) => {
  const subTitle = useMemo(
    () =>
      code === 404
        ? "Sorry, the page you visited does not exist."
        : "Sorry, You must login first.",
    [code]
  );

  return (
    <MainLayout>
      <Content style={styles.mainContent}>
        <Result
          status={code}
          title={`${code}`}
          subTitle={subTitle}
          extra={
            <Link to={"/"}>
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </Content>
    </MainLayout>
  );
};
