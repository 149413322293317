import { FC } from 'react'
import { MainLayout } from '../layout'
import { RouteComponentProps } from '@reach/router';
import { default as HomeStatic } from './home-static.mdx'
import {generateModuleCss} from '../utils'
import { useMediaQuery } from 'react-responsive';
export interface HomePageProps extends RouteComponentProps<{}> {

}
const desktopStyles = generateModuleCss({
        mainContent: {
            padding: '1% 4%',
        },
        welcomeCardTitle: {
            backgroundColor: '#444',
            borderTopLeftRadius: '10px', 
            borderTopRightRadius: '10px'
        },
        cardGridLeft: {
            border: '1px solid #177ddc',
            borderBottomLeftRadius: '10px'
        },
        cardGridRigth: {
            border: '1px solid #177ddc',
            borderBottomRightRadius: '10px'
        },
        cardGrid: {
            border: '1px solid #177ddc'
        },
        cardStyle: { borderRadius: '10px' },
        cardBodyStyle: { display: 'flex' },
        divider: { height:'2%', minHeight: '20px' },
        welcomeCardTypography: { marginTop: '10px'},
        title: { 
            whiteSpace: 'normal', 
            borderTopLeftRadius: '10px',
            borderTopRigthRadius: '10px' 
        }
})

const mobile = generateModuleCss({
    mainContent: {
        padding: '1% 4%',
    },
    welcomeCardTitle: {
        backgroundColor: '#444',
        borderTopLeftRadius: '10px', 
        borderTopRightRadius: '10px'
    },
    cardGridLeft: {
        border: '1px solid #177ddc',
        width: '100%'
    },
    cardGridRigth: {
        border: '1px solid #177ddc',
        width: '100%',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px' 
    },
    cardGrid: {
        border: '1px solid #177ddc',
        width: '100%'
    },
    cardStyle: { borderRadius: '10px' },
    cardBodyStyle: { display: 'flex', flexDirection: 'column' },
    divider: { height:'2%', minHeight: '20px' },
    welcomeCardTypography: { marginTop: '10px'},
    title: { 
        whiteSpace: 'normal', 
        borderTopLeftRadius: '10px',
        borderTopRigthRadius: '10px' 
    }
})

export const HomePage: FC<HomePageProps> = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    
    return(
        <>
            {isDesktopOrLaptop &&
            <MainLayout>
                <HomeStatic styles={desktopStyles}/>
            </MainLayout>}
            {isTabletOrMobile &&
            <MainLayout>
                <HomeStatic styles={mobile}/>
            </MainLayout>}
        </>
    )
}