import { FC } from 'react'
import { RouteComponentProps} from '@reach/router'
import TOS from './tos.mdx'
import { MainLayout } from '../layout'
import { Card, Typography } from 'antd'
import { generateModuleCss } from '../utils'

const desktopStyles = generateModuleCss({
    welcomeCardTitle: {
        backgroundColor: '#444',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px'
    },
    cardStyle: { borderRadius: '10px', margin: '10px' },
    title: { 
        whiteSpace: 'normal', 
        borderTopLeftRadius: '10px',
        borderTopRigthRadius: '10px'
    }
})

export interface TOSProps extends RouteComponentProps<{}> {

}

export const TOSWrapper: FC<TOSProps> = () => {
    return(
    <MainLayout>
        
        <Card
            title={<Typography.Title style={desktopStyles.title}>Terms and Conditions</Typography.Title>}
            headStyle={desktopStyles.welcomeCardTitle}
            style={desktopStyles.cardStyle} 
        >
            <TOS/>
        </Card>
    </MainLayout>)
}