import React from "react";
import { HomePage } from "./components/home";
import { RulesetPage } from "./components/rulesets";
import { FAQWrapper } from "./components/faq";
import { TOSWrapper } from "./components/ToS";
import { ErrorPage } from "./components/utils";
import { Router } from "@reach/router";
import { QueryClient, QueryClientProvider } from "react-query";
import { StoreProvider, createStore } from "easy-peasy";
import { store as storeObj } from "./store";
import { Layout } from "antd";
import "./App.css";
import { UserProfile } from "./components/profile";
import { LoginCallback } from "./components/login-callback";

const { Content, Footer } = Layout;
const queryClient = new QueryClient();

const store = createStore(storeObj);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider store={store}>
        <div className="App">
          <Router>
            <HomePage path={"/"} />
            <TOSWrapper path={"/TermsOfService"} />
            <RulesetPage path={"/ruleset"} />
            <RulesetPage path={"/ruleset/:id"} />
            <FAQWrapper path={"/developers"} />
            <UserProfile path={"/profile"} />
            <LoginCallback path="/login-callback" />
            <ErrorPage default code={404} />
          </Router>
        </div>
      </StoreProvider>
    </QueryClientProvider>
  );
}

export default App;
