import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { State, useStoreState } from "easy-peasy";
import { StoreModel } from "../../store";
export interface LoginCallbackProps extends RouteComponentProps<{}> {}

export const LoginCallback: FC<LoginCallbackProps> = () => {
  const { loginWithRedirect } = useAuth0();
  const redirectUri = useStoreState(
    (state: State<StoreModel>) => state.globalState.redirectUri
  );

  useEffect(() => {
    loginWithRedirect({
      appState: { returnTo: redirectUri },
    });
  }, []);

  return <></>;
};
