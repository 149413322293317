
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { FC } from 'react'
import { Layout, Card, Typography } from 'antd'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout.Content style={props.styles.mainContent}>
    <Card title={<Typography.Title style={props.styles.title}>Welcome to dtection.io!</Typography.Title>} headStyle={props.styles.welcomeCardTitle} style={props.styles.cardStyle} mdxType="Card">
        <Typography.Paragraph style={props.styles.welcomeCardTypography}>
            <p><strong>dtection.io</strong> is a marketplace to a variety of <a href={'./ruleset'}>subscriptions</a> for information security rules, rulesets and signatures. The platform is developed and operated by <a href={'https://3coresec.com'} target='__blank'>3CORESec</a> while the content is developed by ourselves as well as independent information security researchers and vendors.</p>
            <p>Getting started is as simple as:</p>
            <ul>
                <li>Select the subscription you'd like to purchase and complete the checkout process</li>
                <li>You'll receive your unique URL to access the content you purchased in your inbox</li>
                <li>For NIDS-based subscriptions, you can simply add your unique URL to your Snort or Suricata rule management software</li>
                <li>For other formats visit your unique URL (GET) to download the files you purchased or use our <a href={'https://github.com/3CORESec/dtio-kb'} target='__blank'>Marketplace Clients</a></li>
            </ul>
            <p>If you're a researcher doing original work that you'd like to sell through <strong>dtection.io</strong> please visit the <a href={'./developers'}>Researcher Area</a>.</p>
        </Typography.Paragraph>
    </Card>
    <div style={props.styles.divider} />
    <Card headStyle={props.styles.welcomeCardTitle} title={<Typography.Title style={props.styles.title}>Features and Integrations</Typography.Title>} style={props.styles.cardStyle} bodyStyle={props.styles.cardBodyStyle} mdxType="Card">
        <Card.Grid style={props.styles.cardGridLeft} hoverable={false}>
            <Typography.Title>Snort/Suricata</Typography.Title>
            <Typography.Paragraph>
                Subscriptions have out-of-the-box support for <a href={'https://github.com/OISF/suricata-update'} target='__blank'>suricata-update</a>, allowing you to simply reference your subscription URL in the sources file to keep your NIDS deployment updated with the rulesets you purchased. Your unique URL can also be added to any <a href={'https://lawmaker.cloud'} target='__blank'>lawmaker.cloud</a> tenant.
            </Typography.Paragraph>
        </Card.Grid>
        <Card.Grid style={props.styles.cardGrid} hoverable={false}>
            <Typography.Title>Sigma</Typography.Title>
            <Typography.Paragraph>
                Leverage the power of the <a href={'https://github.com/SigmaHQ/sigma'} target='__blank'>Sigma</a> format to reach a wider audience. Submit your content as individual rules or group them by product or technology in one or more subscriptions. Build the content using <strong>sigmac</strong> or 3CORESec's <a href={'https://github.com/3CORESec/SIEGMA'} target='__blank'>SIEGMA</a>.
            </Typography.Paragraph>
        </Card.Grid>
        <Card.Grid style={props.styles.cardGridRigth} hoverable={false}>
            <Typography.Title>Zeek Intel Framework</Typography.Title>
            <Typography.Paragraph>
                Include the indicator lists in Zeek for easy, high-performance alerting using the <a href={'https://docs.zeek.org/en/master/frameworks/intel.html'} target='__blank'>Zeek Intel Framework</a>. Alternatively you can reference your unique subscription URL in Corelight-Update to automatically keep your subscription up-to-date.  
            </Typography.Paragraph>
        </Card.Grid>
    </Card>
    </Layout.Content>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;