import React from 'react';

export type Styles = {
    [key: string]: React.CSSProperties
}

//Make autocompletion and typecheck possible
export const generateModuleCss = <T extends Styles>(styles: T): T => {
    return styles;
}

export enum FilterType {
    AUTHOR = 'Author',
    FORMAT = 'Format'
}