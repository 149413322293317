import { FC, useEffect, useState } from "react";
import { RulesetCard } from "./rule-card";
import { MainLayout } from "../layout";
import { Typography, Layout, notification, Spin, Result, Button } from "antd";
import {
  RouteComponentProps,
  useLocation,
  useParams,
  Link,
} from "@reach/router";
import { useQuery, useMutation } from "react-query";
import { Ruleset, CustomerApi, SubscriptionApi, RulesetApi } from "../../api";
import { AxiosError, AxiosResponse } from "axios";
import { PaymentContainer } from "./payment-container";
import { FilterType, generateModuleCss } from "../utils";
import { useStoreState, useStoreActions, Actions, State } from "easy-peasy";
import { StoreModel } from "../../store";
import { RulesFilter } from "./rule-filter";

const { Title } = Typography;
const { Content } = Layout;
const rulesetApi = new RulesetApi(process.env.REACT_APP_RULESET_API_URL || "");
const subscriptionApi = new SubscriptionApi(
  process.env.REACT_APP_SUBSCRIPTION_API_URL || ""
);
const customerApi = new CustomerApi(
  process.env.REACT_APP_CUSTOMER_API_URL || ""
);

const styles = generateModuleCss({
  contentStyle: { padding: "1% 4%", display: "flex", flexDirection: "column" },
  titleSeparator: { height: "15px" },
  spinCentered: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    justifyContent: "center",
  },
});

export interface RulesetPageProps extends RouteComponentProps<{}> {}

const renderRuleCard = (
  ruleset: Ruleset,
  onSubscribe: () => void,
  pathId?: string
) => {
  const ruleUrl = pathId
    ? window.location.href
    : `${window.location.href}/${ruleset.id}`;
  return (
    <RulesetCard
      ruleset={ruleset}
      onSubscribe={onSubscribe}
      key={`${ruleset.id}-card`}
      url={ruleUrl}
    />
  );
};

const renderRules = (
  rulesets: Ruleset[],
  onSubscribe: (rule: Ruleset) => void,
  pathId?: string
) => {
  if (pathId) {
    console.log(pathId);
    const reqRuleset = rulesets?.filter((it) => it.id === pathId)[0];
    return reqRuleset ? (
      renderRuleCard(reqRuleset, () => onSubscribe(reqRuleset), pathId)
    ) : (
      <Result
        status="404"
        title="404"
        subTitle={`Sorry, the ruleset with id: ${pathId} does not exist.`}
        extra={
          <Link to={"/"}>
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    );
  }

  return rulesets.map((data) => renderRuleCard(data, () => onSubscribe(data)));
};

const getQueryStringParam = (param: string, search: URLSearchParams) => {
  return search.get(param);
};

const applyFilterToRules = (
  rules: Ruleset[],
  filterType?: FilterType,
  filterValue?: string
) => {
  if (!filterType || !filterValue) {
    return rules;
  }

  const res = rules.filter((rule) => {
    if (filterType === FilterType.AUTHOR) {
      return rule.author.name === filterValue;
    }

    return rule.format === filterValue;
  });
  return res;
};

const useGetAllQuery = () =>
  useQuery<Ruleset[], AxiosError>("rulesetAllQuery", async () => {
    const result = await rulesetApi.getAllRuleset();
    return result || [];
  });

const useGetByIdQuery = (id: string) =>
  useQuery<Ruleset[], AxiosError>("rulesetIdQuery", async () => {
    const result = await rulesetApi.getRuleset(id);
    return [result] || [];
  });

export const RulesetPage: FC<RulesetPageProps> = () => {
  const [selectedRuleset, setSelectedRuleset] = useState<Ruleset>();
  const [busy, setBusy] = useState<boolean>(false);
  const filterType = useStoreState(
    (state: State<StoreModel>) => state.rulesetState.filterType
  );
  const filter = useStoreState(
    (state: State<StoreModel>) => state.rulesetState.filter
  );
  const getDataFromRules = useStoreActions(
    (actions: Actions<StoreModel>) => actions.rulesetState.getDataFromRules
  );

  const params = useParams();
  const location = useLocation();
  const useGetByIdQuery = Boolean(params.id);

  const {
    data: allRulesets = [],
    error: rulesetQueryError,
    isLoading,
  } = useQuery<Ruleset[], AxiosError>(
    "rulesetAllQuery",
    async () => {
      if (!params.id) {
        const result = await rulesetApi.getAllRuleset();
        return result || [];
      }

      const result = await rulesetApi.getRuleset(params.id);
      return [result] || [];
    },
    {
      onSuccess: (data) => {
        getDataFromRules(data);
      },
      enabled: !useGetByIdQuery,
    }
  );

  //used when fetching a ruleset by id
  const {
    data: idRuleset = [],
    error: rulesetIdQueryError,
    isLoading: isLoadingById,
  } = useQuery<Ruleset[], AxiosError>(
    "rulesetByIdQuery",
    async () => {
      const result = await rulesetApi.getRuleset(params.id);
      return [result] || [];
    },
    {
      onSuccess: (data) => {
        getDataFromRules(data);
      },
      enabled: useGetByIdQuery,
    }
  );

  const { error: createSubscriptionError, mutate: createSubscription } =
    useMutation<
      AxiosResponse<any>,
      AxiosError,
      { email: string; rulesetId: string }
    >(({ email, rulesetId }) =>
      subscriptionApi.postSubscription(email, rulesetId)
    );

  const fulfillSubscription = async (sessionId: string, rulesetId: string) => {
    const email = await customerApi.getCustomerFromSessionId(sessionId);
    createSubscription({ email, rulesetId });
    setBusy(false);
  };

  useEffect(() => {
    const err = rulesetQueryError || rulesetIdQueryError;
    if (err) {
      return notification.error({
        message: err.message || "Ups something went wrong!",
      });
    }
  }, [rulesetQueryError, rulesetIdQueryError]);

  useEffect(() => {
    if (createSubscriptionError) {
      return notification.error({
        message: createSubscriptionError.message || "Ups something went wrong!",
      });
    }
  }, [createSubscriptionError]);

  useEffect(() => {
    const paymentSuccessParam = getQueryStringParam(
      "session_id",
      new URLSearchParams(location.search)
    );
    const ruleset = getQueryStringParam(
      "ruleset",
      new URLSearchParams(location.search)
    );

    if (paymentSuccessParam && ruleset) {
      setBusy(true);
      try {
        window.history.replaceState({}, "", location.pathname);
        notification.info({
          message:
            "Subscription added succesfully, check your email for more details",
        });
        fulfillSubscription(paymentSuccessParam, ruleset);
      } catch (e: any) {
        setBusy(false);
        return notification.error({
          message: e.message || "Ups something went wrong!",
        });
      }
    }
  }, [location]);

  const rulesToDisplay = params.id ? idRuleset : allRulesets;

  return (
    <MainLayout>
      <Content style={styles.contentStyle} key={"ruleset-page-content"}>
        <div style={styles.titleSeparator} key={"ruleset-title-div"} />

        {!params.id && (
          <Title key={"title-ruleset"}>
            Available Rulesets
            {!isLoading && !isLoadingById && !busy && (
              <RulesFilter style={{ float: "right" }} />
            )}
          </Title>
        )}

        {isLoading || busy || isLoadingById ? (
          <div style={styles.spinCentered} key={"ruleset-spinner-div"}>
            <Spin tip={"Loading"} />
          </div>
        ) : (
          renderRules(
            applyFilterToRules(rulesToDisplay, filterType, filter),
            setSelectedRuleset,
            params.id
          )
        )}
      </Content>
      <PaymentContainer
        onCancel={() => setSelectedRuleset(undefined)}
        visible={Boolean(selectedRuleset)}
        prices={selectedRuleset?.prices || []}
        ruleset={selectedRuleset}
      />
    </MainLayout>
  );
};
