import { FC } from 'react'
import { RouteComponentProps} from '@reach/router'
import FAQ from './faq-static.mdx'
import { MainLayout } from '../layout'
import { generateModuleCss } from '../utils'

const desktopStyles = generateModuleCss({
    mainContent: {
        padding: '1% 4%',
    },
    welcomeCardTitle: {
        backgroundColor: '#444',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px'
    },
    cardGrid: {
        border: '1px solid #177ddc',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        width: '100%'
    },
    cardStyle: { borderRadius: '10px' },
    cardBodyStyle: { display: 'flex' },
    divider: { height:'2%', minHeight: '20px' },
    welcomeCardTypography: { marginTop: '10px'},
    title: { 
        whiteSpace: 'normal', 
        borderTopLeftRadius: '10px',
        borderTopRigthRadius: '10px'
    }
})

export interface FaqProps extends RouteComponentProps<{}> {

}

export const FAQWrapper: FC<FaqProps> = () => {
    return(
    <MainLayout>
        <FAQ styles={desktopStyles}/>
    </MainLayout>)
}